.address {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 240px;
    height: 32px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipsis;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    margin-top: 12px;
}

.map-marker {
    margin-top: -35px;
    font-weight: bolder;
    text-shadow: 1px 1px 1px dodgerblue;
}

.places-container {
    top: 15px;
    left: 50%;
    z-index: 10;
    width: 300px;
    position: absolute;
    align-items: center;
    transform: translateX(-50%);
}

.map-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
