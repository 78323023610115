*::-webkit-scrollbar {
  display: flex;
  width: 6px;
  height: 6px;
}
  
*::-webkit-scrollbar-track {
  display: flex;
  margin-top: 13px;
  margin-left: 13px;
  margin-right: 13px;
  margin-bottom: 13px;
  
}
  
*::-webkit-scrollbar-thumb {
  display: flex;
  background-color: dodgerblue;
  border-radius: 20px;
  border: 1px solid gray;
}

/*------ Style 1 ------*/
.color-picker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border: 2px solid dodgerblue;
  border-radius: 15px;
}
.color-picker::-webkit-color-swatch {
  border-radius: 50px;
  border: none;
}
.color-picker::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}
